import React from "react";
import "./FeaturePoint.scss";

export function FeaturePoint(props) {
    return(
        <div className="FeaturePoint">
            <img width="100px" height="100px" src={props.image} alt="" />
            <h1>{props.title}</h1>
            <p>{props.description}</p>
        </div>
    )
}