import React from "react";
import './App.scss';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { Home, Projects, Contact, Article } from "./pages";
import { Footer, Header, ScrollToTop } from "./components";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <ScrollToTop />
        <Switch>
            <Route path="/science-support">
                <Article title="Science support">
                    We closely cooperate with local Universities and Institutes.
                    Our focus is to support science.
                </Article>
            </Route>
            <Route path="/experimental-design">
                <Article title="Experimental design">
                    <p>As a group of experts, we are taking our internal time to develop best process flows.
                    We will probably take your challenge from cycle loop to clear results. If it is a chemical, biotech, life-sciences,
                    analytical or other field related process we will strengthen your experimental design or start a new one.</p>
                    If it is necessary to scale your processes or require specific hardware, then we can design specific tools for your process purpose.
                </Article>
            </Route>
            <Route path="/customer-development">
                <Article title="Customer Development">
                    <p>We are oriented to our customers and their requirements. No matters starting from a scratch or continuing in some phase of technology development we are fully able to support.
                    In most cases technology needs to be transferred from lab to production, for us it is a reachable target.</p>
                    <p>We are using analytical and statistical methods to get desired results.</p>
                    <p>Internal quality assurance helps to strengthen the processes.</p>
                </Article>
            </Route>
            <Route path="/projects">
                <Projects />
            </Route>
            <Route path="/contact">
                <Contact />
            </Route>
            <Route path="/">
                <Home />
            </Route>
        </Switch>
      <Footer />
      </Router>
    </div>
  );
}

export default App;
