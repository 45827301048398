import React from "react";
import "./Footer.scss";
import {
    Container
} from "rbx";
import {
    Link
} from "react-router-dom";
import {ContactLists} from './ContactLists';

export function Footer() {
    return(
        <footer className="Footer">
            <Container>
                <div className="horizontal-center">
                    <div className="col left">
                        <ul>
                            <li><Link to="/">About</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/projects">Projects</Link></li>
                            <li><Link to="/contact">Contact</Link></li>
                        </ul>
                        <div className="copyright">
                            BioX Technologies s.r.o. ©{new Date().getFullYear()}
                        </div>
                    </div>
                    <div className="col right">
                        <ContactLists mobileOnly={false} />
                    </div>
                </div>
            </Container>
        </footer>
    )
}