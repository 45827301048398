import React from "react";
import {
    Column,
    Container,
    Hero,
    Title
} from "rbx";
import {
    FeaturePoint,
    ProjectCard
} from "../components/Home";
// import {
//     MicroscopeDecoration
// } from "../components";
import "./Home.scss";

export function Home() {
    return (
        <div className="Home">
            <div className="intro-image" />
            <Container>
                <section className="section-intro">
                    <Hero color="info" size="medium">
                        <Hero.Body>
                            <Container>
                                <Title>
                                    BioX Technologies
                                </Title>
                                <Title subtitle as="h2">
                                    <div className="scrim">
                                        BioX Technologies is SME that consists of young experts in the field of biotechnology,
                                        process automation and environmental technology. We support our customers with new ideas and
                                        outstanding realisation of projects. No matter if you need to solve basic R&D in the lab or
                                        complex issues in technology development.
                                    </div>
                                </Title>
                            </Container>
                        </Hero.Body>
                    </Hero>
                </section>
                <section className="section-features">
                    <Column.Group>
                        <Column>
                            <FeaturePoint
                                image="img/features1.png"
                                title="University cooperation"
                                description="Excellent connection to the University gives us a wide range of experts from different areas."
                            />
                        </Column>
                        <Column>
                            <FeaturePoint
                                image="img/features2.png"
                                title="Space science"
                                description="Life support systems in the aerospace industry is one of our main R&D programmes."
                            />
                        </Column>
                        <Column>
                            <FeaturePoint
                                image="img/features3.png"
                                title="Experience"
                                description="We have expertise in technology development, R&D, computer science, industrial automation. We have QA/QC internal processes."
                            />
                        </Column>
                    </Column.Group>
                    <Column.Group>
                        <Column>
                            <FeaturePoint
                                image="img/features4.png"
                                title="Quality equipment"
                                description="You can find quality production and analytical equipment in our labs."
                            />
                        </Column>
                        <Column>
                            <FeaturePoint
                                image="img/features5.png"
                                title="Own investigation"
                                description="Most of our own development is in bacterial cultivations with enzymes production."
                            />
                        </Column>
                        <Column>
                            <FeaturePoint
                                image="img/features6.png"
                                title="Biotechnologies"
                                description="Green technologies are the future for sustainable ecosystems on the blue planet. We are a part of it."
                            />
                        </Column>
                    </Column.Group>
                </section>
                <section className="section-projects">
                    <div className="separator-title">
                        <Title as="h1">Projects</Title>
                    </div>
                    <Column.Group>
                        <Column>
                            <ProjectCard
                                image="img/RECOVER2.jpg"
                                title="Arthrospira - Biomass Recovery"
                                description="Successful Project in cooperation with ESA. Screening of recovery methods for solid-liquid separation, with eventually scaling to zero-g. HW demonstrator had been described."
                                shortenText={true}
                                link="projects"
                            />
                        </Column>
                        <Column>
                            <ProjectCard
                                image="img/LYO4.jpg"
                                title="smART DRYer"
                                description="Freeze dryer for small commercial applications for dehydrating various foods. Device is also usable in lyophilization process in pharma industry"
                                shortenText={true}
                                link="projects"
                            />
                        </Column>
                        <Column>
                            <ProjectCard
                                image="img/LIPIDOM1.jpg"
                                title="Limnospira - Lipidom & Pathways"
                                description="Describing of metabolome, and white box approach is essential in cell strains stability used for long-term space missions. Project is in cooperation with STU BA, UK BA and MELiSSA partners and is focused in biochemistry and analytics."
                                shortenText={true}
                                link="projects"
                            />
                        </Column>
                    </Column.Group>
                    <Column.Group>
                        <Column>
                            <ProjectCard
                                image="img/FEEDER1.png"
                                title="MELiSSA Feeder"
                                description="Designing of device used for the Recovery of cell paste in spirulina industry. Device will be scalable and suitable for various applications in commercial sector as food or pharma industry."
                                shortenText={true}
                                link="projects"
                            />
                        </Column>
                        <Column>
                            <ProjectCard
                                image="img/project_design_of_devices.jpg"
                                title="Design of devices"
                                description="We have designed pilot-scale experimental devices and full-scale production lines. These are used in diverse industires and research centers."
                                shortenText={true}
                                link="projects"
                            />
                        </Column>
                        <Column>
                            <ProjectCard
                                image="img/project_purification.jpg"
                                title="Metabolite purification"
                                description="Primary and secondary metabolites could be effectively purified for desired product. Sensitive analytical methods and devices are used to achive optimal results."
                                shortenText={true}
                                link="projects"
                            />
                        </Column>
                    </Column.Group>
                    <Column.Group>
                        <Column>
                            <ProjectCard
                                image="img/bacterial_cultivations.jpg"
                                title="Bacterial cultivations"
                                description="Experiments in microbial cultivation does not have scale and limits! We have yearly experiences in small scale to large scale cultivations, their optimalization and technology transfer."
                                shortenText={true}
                                link="projects"
                            />
                        </Column>
                        <Column></Column>
                        <Column></Column>
                    </Column.Group>
                </section>
                <section className="section-partners">
                    <div className="separator-title">
                        <Title as="h1">Our partners</Title>
                    </div>
                    <Column.Group>
                        <Column>
                            <img src="img/partner_stu.png" alt=""/>
                        </Column>
                        <Column>
                            <img src="img/partner_esa.png" alt=""/>
                        </Column>
                        <Column>
                            <img src="img/partner_lonza.png" alt=""/>
                        </Column>
                        <Column>
                            <img src="img/partner_saneca_pharma.png" alt=""/>
                        </Column>
                    </Column.Group>
                </section>
            </Container>
        </div>
    );
}
