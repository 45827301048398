import {Navbar, Container} from "rbx";
import {Link, useLocation} from "react-router-dom";
import React, {useState}  from "react";

import "./Header.scss";

export function Header(props) {

    let location = useLocation();
    const [isGreen, setIsGreen] = useState(true);

    React.useEffect(() => {
        if(location.pathname === '/' || location.path === '') {
            setIsGreen(false);
        } else {
            setIsGreen(true);
        }
    }, [location]);

    return (
            <Navbar transparent className={isGreen ? "green-background Header" : "Header"} color="info">
                <Container>
                    <Navbar.Brand>
                        <Navbar.Item className="logo" as="div" />
                        <Navbar.Burger />
                    </Navbar.Brand>
                    <Navbar.Menu>
                        <Navbar.Segment align="end">
                            <Link to="/">
                                <Navbar.Item className="mainMenuItem">
                                    About us
                                </Navbar.Item>
                            </Link>
                            <Navbar.Item className="mainMenuItem" dropdown>
                                <Navbar.Item>
                                    Services ▼
                                </Navbar.Item>
                                <Navbar.Dropdown>
                                    <Link to="/customer-development">
                                        <Navbar.Item>Customer development</Navbar.Item>
                                    </Link>
                                    <Link to="/experimental-design">
                                        <Navbar.Item>Experimental design</Navbar.Item>
                                    </Link>
                                    <Link to="/science-support">
                                        <Navbar.Item>Science support</Navbar.Item>
                                    </Link>
                                </Navbar.Dropdown>
                            </Navbar.Item>
                            <Link to="/projects">
                                <Navbar.Item className="mainMenuItem">
                                    Projects
                                </Navbar.Item>
                            </Link>
                            <Link to="/contact">
                                <Navbar.Item className="mainMenuItem">
                                    Contact
                                </Navbar.Item>
                            </Link>
                        </Navbar.Segment>
                    </Navbar.Menu>
                </Container>
            </Navbar>
    )
}