import {Column, Title} from "rbx";
import {ProjectCard} from "../components/Home";

import './Project.scss';

export function Projects() {
    return (
        <div className="Projects">
            <div className="separator-title">
                <Title as="h1">Projects</Title>
            </div>
            <Column.Group>
                <Column>
                    <ProjectCard
                        image="img/RECOVER2.jpg"
                        title="Arthrospira - Biomass Recovery"
                        bodyImages={["img/RECOVER1.jpg","img/RECOVER2.jpg"]}
                        description="Successful Project in cooperation with ESA. Screening of recovery methods for solid-liquid separation, with eventually scaling to zero-g. HW demonstrator had been described."
                        shortenText={false}
                    />
                </Column>
                <Column>
                    <ProjectCard
                        image="img/LYO4.jpg"
                        bodyImages={["img/LYO4.jpg","img/LYO1.JPG", "img/LYO2.png"]}
                        title="smART DRYer"
                        description="Freeze dryer for small commercial applications for dehydrating various foods. Device is also usable in lyophilization process in pharma industry"
                        shortenText={false}
                    />
                </Column>
            </Column.Group>
            <Column.Group>
                <Column>
                    <ProjectCard
                        image="img/LIPIDOM1.jpg"
                        bodyImages={["img/LIPIDOM1.jpg"]}
                        title="Limnospira - Lipidom & Pathways"
                        description="Describing of metabolome, and white box approach is essential in cell strains stability used for long-term space missions. Project is in cooperation with STU BA, UK BA and MELiSSA partners and is focused in biochemistry and analytics."
                        shortenText={false}
                    />
                </Column>
                <Column>
                    <ProjectCard
                        image="img/FEEDER1.png"
                        bodyImages={["img/FEEDER1.png"]}
                        title="MELiSSA Feeder"
                        description="Designing of device used for the Recovery of cell paste in spirulina industry. Device will be scalable and suitable for various applications in commercial sector as food or pharma industry."
                        shortenText={false}
                    />
                </Column>
            </Column.Group>
            <Column.Group>
                <Column>
                    <ProjectCard
                        image="img/project_design_of_devices.jpg"
                        title="Design of devices"
                        description="We have designed pilot-scale experimental devices and full-scale production lines. These are used in diverse industires and research centers."
                        shortenText={false}
                    />
                </Column>
                <Column>
                    <ProjectCard
                        image="img/project_purification.jpg"
                        title="Metabolite purification"
                        description="Primary and secondary metabolites could be effectively purified for desired product. Sensitive analytical methods and devices are used to achive optimal results."
                        shortenText={false}
                    />
                </Column>
            </Column.Group>
            <Column.Group>
                <Column>
                    <ProjectCard
                        image="img/bacterial_cultivations.jpg"
                        bodyImages={["img/bacterial_cultivations.jpg"]}
                        title="Bacterial cultivations"
                        description="Experiments in microbial cultivation does not have scale and limits! We have yearly experiences in small scale to large scale cultivations, their optimalization and technology transfer."
                        shortenText={false}
                    />
                </Column>
                <Column></Column>
            </Column.Group>
        </div>
    )
}
