import { useState, useEffect } from 'react';
import {
    Field,
    Label,
    Control,
    Input,
    Icon,
    Container,
    Textarea,
    Button, Title,
    Message
} from "rbx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUser, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import { ContactLists } from '../components/ContactLists';
import './Contact.scss';

export function Contact() {
    // React state hook
    const [nameForm, setNameForm] = useState("/");
    const [emailForm, setEmailForm] = useState("/");
    const [phoneForm, setPhoneForm] = useState("/");
    const [messageForm, setMessageForm] = useState("/");
    const [canSendForm, setCanSendForm] = useState(false);
    const [formResponse, setFormResponse] = useState('');

    // Effect hook gets called every time the component updates
    // Similar to 'componentDidUpdate'
    useEffect(() => {
        updateCanSendForm()
    });

    // Path to the PHP file that handles the contact form
    const CONTACT_API_PATH = "/bat/MailHandler.php";

    // A method that sends the contact form to the backend
    const sendMessage = () => {
        const bodyValue = JSON.stringify(
            {
                name: nameForm,
                email: emailForm,
                phone: phoneForm,
                message: messageForm,
                fax: 'nope',
                state: 'nope',
                stripHTML: 'true'
            }
        );
        console.log(bodyValue);
        const response = fetch(CONTACT_API_PATH, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: bodyValue
        })
        .then(response => response.text())
        .then((data) => {
            setFormResponse(data);
            setNameForm('/');
            setEmailForm('/');
            setPhoneForm('/');
            setMessageForm('/');
            setCanSendForm(false);
        })
    }

    // Event handlers for form fields
    const onFieldChangeName = (event) => {
        setNameForm(event.target.value);
    }

    const onFieldChangeEmail = (event) => {
        setEmailForm(event.target.value);
    }

    const onFieldChangePhone = (event) => {
        setPhoneForm(event.target.value);
    }

    const onFieldChangeMessage = (event) => {
        setMessageForm(event.target.value);
    }

    const updateCanSendForm = () => {
        if(
            nameForm !== '' && nameForm !== '/' &&
            phoneForm !== '' && phoneForm !== '/' &&
            emailForm !== '' && emailForm !== '/' &&
            messageForm !== '' && messageForm !== '/'
        ) {
            setCanSendForm(true);
        } else {
            setCanSendForm(false);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        sendMessage();
    }

    return (
        <div className="Contact">
            <Container>
                <div className="separator-title">
                    <Title as="h1">Contact</Title>
                </div>
                    <form onSubmit={handleSubmit}>
                    <Field horizontal>
                        <Field.Label size="normal">
                            <Label>From</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control expanded iconLeft>
                                    <Input
                                        onChange={onFieldChangeName}
                                        type="text"
                                        placeholder="Name"
                                        color={nameForm === "" ? "danger" : ""}
                                    />
                                    <Icon size="small" align="left">
                                        <FontAwesomeIcon icon={faUser} />
                                    </Icon>
                                </Control>
                            </Field>
                            <Field>
                                <Control expanded iconLeft iconRight>
                                    <Input
                                        onChange={onFieldChangeEmail}
                                        placeholder="Email"
                                        type="email"
                                        color={emailForm === "" ? "danger" : ""}
                                    />
                                    <Icon size="small" align="left">
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </Icon>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>

                    <Field horizontal>
                        <Field.Label />
                        <Field.Body>
                            <Field expanded>
                                <Control expanded iconLeft>
                                    <Input
                                        onChange={onFieldChangePhone}
                                        type="tel"
                                        placeholder="Your phone number"
                                        color={phoneForm === "" ? "danger" : ""}
                                    />
                                    <Icon size="small" align="left">
                                        <FontAwesomeIcon icon={faPhone} />
                                    </Icon>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>

                    <Field horizontal>
                        <Field.Label size="normal">
                            <Label>Question</Label>
                        </Field.Label>
                        <Field.Body>
                            <Field>
                                <Control>
                                    <Textarea
                                        onChange={onFieldChangeMessage}
                                        placeholder="Explain how we can help you"
                                        color={messageForm === "" ? "danger" : ""}
                                    />
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>

                    <Field horizontal>
                        <Field.Label /> {/* Left empty for spacing  */}
                        <Field.Body>
                            <Field>
                                <Control>
                                    <Button disabled={!canSendForm} tooltip={!canSendForm ? "All fields must be filled out to submit the form" : ""} color="primary">Send message</Button>
                                </Control>
                            </Field>
                        </Field.Body>
                    </Field>
                </form>

                <ContactLists mobileOnly={true} />
                {formResponse ?
                <Message color={formResponse === "formResponse" ? "success" : "danger"}>
                    <Message.Body>
                    {formResponse}
                    </Message.Body>
                </Message> : ''}
            </Container>
        </div>
    )
}